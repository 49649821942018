import { CapacitorUpdater, DownloadEvent } from '@capgo/capacitor-updater'
import { IndexDBService } from '@eo-storefronts/eo-core'
import { Player } from '@lottiefiles/react-lottie-player'
import { Box, LinearProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Title from '~/src/components/mui-wrappers/Title'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import { AppInjector } from '~/src/utils/DependenciesInjectionUtils'

const InAppUpdate = () => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()
  const [ searchParams ] = useSearchParams()
  const [ progress, setProgress ] = useState<number>(0)

  useLayout('update')

  useEffect(() => {
    CapacitorUpdater.addListener('download', ({ percent }: DownloadEvent) => {
      setProgress(percent)
    })

    CapacitorUpdater.download({
      url: `https://rmxprodeuwstorage.blob.core.windows.net/prod/click-and-collect/apps/versions/${searchParams.get('version')}.zip`,
      version: searchParams.get('version') || ''
    }).then(async (version) => {
      await AppInjector.get<IndexDBService>(IndexDBService).set('inAppVersion', searchParams.get('version'))
      await CapacitorUpdater.set(version)
    })

    return () => {
      void CapacitorUpdater.removeAllListeners()
    }
  }, [])

  return (
    <Box>
      <Player
        src='/images/lotties/in-app-update.json'
        autoplay
        loop
        style={{
          width: '100%'
        }}
      />
      <Title
        variant='h4'
        sx={{
          mt: 4,
          mb: 2,
          textAlign: 'center',
          px: 4,
          [muiTheme.breakpoints.down('sm')]: {
            px: 2
          }
        }}>
        {t('updateApp.title')}
      </Title>
      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          px: 4,
          [muiTheme.breakpoints.down('sm')]: {
            px: 2
          }
        }}
      >
        {t('updateApp.subTitle')}
      </Typography>

      <LinearProgress
        variant='determinate'
        value={progress}
        sx={{
          mt: 3,
          ml: '10%',
          width: '80%'
        }}
      />

      <Typography
        sx={{
          width: '80%',
          textAlign: 'center',
          fontStyle: 'italic',
          mt: 2,
          mx: 'auto'
        }}
      >
        {t('updateApp.downloadWillStartAutomatically')}
      </Typography>
    </Box>
  )
}

export default InAppUpdate
